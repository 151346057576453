import React, { useEffect, useState } from "react";
import { Buffer } from 'buffer';
import { useParams } from "react-router-dom";
import axios from "axios";
import Loading from "./components/loading";
// -----------------
// Bandeau déroulant
// -----------------
// import Bandeau from "./components/bandeau"
import "./App.css";
import "./index.css";
import zlib from "react-zlib-js";
import base64url from "base64url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faAt } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import quipoFigure from "../src/assets/img/QuipoFigure.png";

const App = () => {
  const { tsid } = useParams();
  const [storeData, setStoreData] = useState(null);
  const currentYear = new Date().getFullYear();
  const baseUrlQuipo = "https://app.quipo-app.com/mobileapp?";
  const [buttonClass, setButtonClass] = useState('');
  const [ticketZero, setTicketZero] = useState(null);
  const [gameUrl, setGameUrl] = useState(null);


  const compressData = (data, callBack)  => {
    zlib.deflate(
      Buffer.from(data),
      { level: zlib.Z_BEST_COMPRESSION },
      (errors, dataDecoded) => {
        if (errors) {
          callBack("", errors);
        } else {
          callBack(dataDecoded, errors);
        }
      }
    );
  };

  function updateButton () {
    
    // Sélectionner l'élément parent
    const parentElement = document.querySelector('.shop-page-header');

    // Récupérer la couleur de fond du parent
    const parentBgColor = window.getComputedStyle(parentElement).backgroundColor;

    // Fonction pour vérifier si une couleur est claire ou foncée
    const isLightColor = (rgbColor) => {
      const rgbValues = rgbColor.match(/\d+/g).map(Number); // Extraire les valeurs RGB
      // Calcul de la luminance relative de la couleur
      const luminance = (0.299 * rgbValues[0] + 0.587 * rgbValues[1] + 0.114 * rgbValues[2]) / 255;
      return luminance > 0.5; // Si la luminance est supérieure à 0.5, c'est une couleur claire
    };

    // Conditionner la classe du bouton en fonction de la couleur de fond du parent
    if (isLightColor(parentBgColor)) {
      setButtonClass('dark-bg');
    } else {
      setButtonClass('light-bg');
    }
  }

  function darkenColor (color, percent) {
    // Convertir la couleur hexadécimale en un nombre
    let num = parseInt(color.slice(1), 16),
        amt = Math.round(2.55 * percent); // Calcul du montant à soustraire

    // Extraire les composantes RGB
    let R = (num >> 16) & 0xFF,
        G = (num >> 8) & 0xFF,
        B = num & 0xFF;

    // Identifier la valeur la plus grande parmi R, G, B
    let max = Math.max(R, G, B);

    // Assombrir seulement les composantes qui ne sont pas les plus grandes
    if (R !== max) R = Math.max(0, R - amt);
    if (G !== max) G = Math.max(0, G - amt);
    if (B !== max) B = Math.max(0, B - amt);

    // Reconstituer la couleur hexadécimale après l'assombrissement
    return '#' + ((1 << 24) + (R << 16) + (G << 8) + B).toString(16).slice(1).toUpperCase();
  }

  useEffect(() => {
    if (tsid) {
      axios
        .get(`https://tl-cdn.occiback.dev/v2/store/${tsid}.json`)
        .then((response) => {
          //----------------------------
          //Gestion du cas où le magasin n'a pas le theme extra.
          //Dans ce cas il faut mettre une configuration par défaut au niveau des couleurs
          //----------------------------v
          console.log(response.data)
          if (response.data.themeExtras) {
            setStoreData(response.data);
          } else {
            let jsonData = response.data
            jsonData.themeExtras = {
                "widget": {
                    "fidelity": [
                        {
                            "background": "#7F7F7F",
                            "text": "#ffffff",
                            "title": "#ffffff"
                        }
                    ]
                },
                "store": {
                    "buttons": {
                        "textSize": 16,
                        "textAlignment": "center",
                        "textBackground": "rgba(0, 0, 0, 0.4)",
                        "textFont": "PTMono-Regular",
                        "textColor": "#ffffff"
                    },
                    "background": "#000000",
                    "display": [
                        "title"
                    ],
                    "dot": [
                        {
                            "color": "#FFFFFF",
                            "background": "#FFFFFF"
                        }
                    ],
                    "geometrie": "none",
                    "theme": "simple",
                    "text": "#fff9ebff",
                    "title": {
                        "textSize": 16,
                        "textAlignment": "center",
                        "textFont": "NeueMachina-Ultrabold",
                        "textColor": "#285136"
                    }
                },
                "ticket": {
                    "theme": {
                        "primary": "#fafafaff",
                        "secondary": "#3d3d3dff",
                        "borderColor": "#c7c7c7ff"
                    }
                }
            };
            setStoreData(jsonData);
          }
          let backgroundButton = darkenColor(response.data.themeExtras.store.background, 10);  // 20% plus sombre
          let backgroundDarken = darkenColor(response.data.themeExtras.store.background, 20);  // 20% plus sombre
          let borderDarken = darkenColor(response.data.themeExtras.store.background, 30);  // 20% plus sombre
          document.documentElement.style.setProperty('--background-button-darken', backgroundButton);
          document.documentElement.style.setProperty('--background-color-darken', backgroundDarken);
          document.documentElement.style.setProperty('--background-border-darken', borderDarken);

          const dataZero = {
            tsid: response.data.tsid,
            storeName: response.data.title,
            storeAddress: response.data.addressAddress,
            v: 1000002208000000000,
          };
          compressData(JSON.stringify(dataZero), (data, error) => {
            if (error) {
              console.log("WRONG DATA TICKET ZERO!");
            } else {
              const dataString = base64url.fromBase64(
                Buffer.from(data).toString("base64")
              );
              setTicketZero(baseUrlQuipo + encodeURI(dataString));
            }
          });
          updateButton();
        })
        .catch((error) => {
          console.error(
            "Erreur lors de la récupération des données du magasin :(",
            error
          );
        });
      axios.get(`https://tl-cdn.occiback.dev/v2/game/${tsid}.json`).then((response) => {
        const dataGame = {
          id: response.data[0].id,
          gname: response.data[0].gname,
          gdesc: response.data[0].gdesc,
          qrcode: response.data[0].extras.qrcode,
        };
        compressData(JSON.stringify(dataGame), (data, error) => {
          if (error) {
            console.log("WRONG DATA GAME!");
          } else {
            setGameUrl(dataGame.qrcode);
          }
        });
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération des données du jeu :(",
          error
        );
      });
    }
  }, [tsid]);

  if (!storeData) return <Loading />;

  const handleClick = () => {
    if (!ticketZero)
    {
      console.log("C'EST VIDE");
    } else {
      console.log("ticket Zero:",ticketZero);
      window.open(ticketZero, "_blank");
    }
  };

  const handleClickGame = () => {
    if (!gameUrl)
    {
      console.log("L'url du game EST VIDE");
    } else {
      window.open(gameUrl, "_blank");
    }
  };

  return (
    <div className="main-container">
      <div className="shop-page" >
        <div className="shop-page-header" style={{ "--store-background": storeData.themeExtras.store.background, "--store-text": storeData.themeExtras.store.text }}>
          <header>
            <div className="logo-div">
              {/*Gestion du cas où il n'y a pas de logo. On affiche quand même le nom du magasin*/}
              {storeData.logoMain ? <img className="shop-logo"
                src={`https://tl-cdn.occiback.dev/res/logo/${
                  storeData.logoMain
                }.${storeData.logoMainFtype.toLowerCase()}`}
                alt="shop-logo"
              /> : null }
            </div>
            {storeData.logoMain ? <p 
            className="address-shop"
            style={{"--store-text": storeData.themeExtras.store.text }}>
              {storeData.addressAddress}
            </p> : null}
          </header>
          <section
            className="shop-page-header-deep"
            style={{"--store-text": storeData.themeExtras.store.text }}
          >
            <h1 className="title-shop">
              {storeData.title.toUpperCase()}
            </h1>
            {storeData.logoMain ? null : <p 
            className="address-shop"
            style={{"--store-text": storeData.themeExtras.store.text }}>
              {storeData.addressAddress}
            </p>}
            <p className="invite-shop">
              {" "}
              vous propose d'entrer dans sa communauté QUIPO et de profiter d'une expérience client unique!
            </p>
          </section>
          { gameUrl ?
          <button onClick={() => handleClickGame()} className={`action-button ${buttonClass} --background-color-darken --background-border-darken --background-button-darken`}>
            JOUEZ !
          </button> : null}
          <div className="logo-quipo-frame">
            <img src={quipoFigure} alt="logoQuipo" className={`logo-quipo ${buttonClass}`}/>
          </div>
          <button onClick={() => handleClick()} className={`action-button ${buttonClass} --background-color-darken --background-border-darken --background-button-darken`}>
            DÉMARRER L'EXPÉRIENCE
          </button>
        </div>

        <div className="separator-container">
          <div className="separator">
            <div className="separator-point left-point"></div>
            <div className="separator-line"></div>
            <div className="separator-point right-point"></div>
          </div>
        </div>

        <div className="shop-media">
          <a href={storeData.phoneAddress} className="phone-button">
            <FontAwesomeIcon icon={faPhone} />
          </a>
          {storeData.socialNetworks?.facebook ? (
            <a
              href={storeData.socialNetworks.facebook}
              className="otre-button"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          ) : null}
          {storeData.socialNetworks?.instagram ? (
            <a
              href={storeData.socialNetworks.instagram}
              className="otre-button"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          ) : null}
          {storeData.socialNetworks?.linkedin ? (
            <a
              href={storeData.socialNetworks.linkedin}
              className="otre-button"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          ) : null}
          {storeData.socialNetworks?.website ? (
            <a
              href={storeData.socialNetworks.website}
              className="otre-button"
            >
              <FontAwesomeIcon icon={faAt} />
            </a>
          ) : null}
        </div>

        <div className="separator-container">
          <div className="separator">
            <div className="separator-point left-point"></div>
            <div className="separator-line"></div>
            <div className="separator-point right-point"></div>
          </div>
        </div>

        {/* Bandeau déroulant informatif */}

        {/*<div className="shop-alert" style={{ "--ticket-primary": storeData.themeExtras.ticket.theme.primary}}>
            <Bandeau text={textBandeau.toUpperCase()} />
        </div>

        <div className="separator-container">
          <div className="separator">
            <div className="separator-point left-point"></div>
            <div className="separator-line"></div>
            <div className="separator-point right-point"></div>
          </div>
        </div>*/}

        <div className="shop-info" >
          <p className="quote-text">
            " {storeData.description} "
          </p>
          <p className="copyright">
            &copy; {currentYear} QUIPO. Tous droits réservés.
          </p>
        </div>
      </div>
    </div>
  );
};

export default App;